import { trackEvent } from "/services/bigbrain-service";
const Sentry = require("@sentry/nextjs");
import { GLOVE_FEEDBACK_POPUP_CLOSED, GLOVE_FEEDBACK_POPUP_OPENED, GLOVE_FEEDBACK_POPUP_SUBMITTED, GLOVE_FEEDBACK_POPUP_USER_START_TYPING } from "/constants/bigbrain-event-types";
const GloveDataEvents = {
    POPUP_OPENED: "FeedbackPopupOpened",
    USER_START_TYPING: "FeedbackPopupUserStartTyping",
    FEEDBACK_SUBMITTED: "FeedbackPopupSubmitted",
    POPUP_CLOSED: "FeedbackPopupClosed"
};
const eventHandlers = {
    [GloveDataEvents.POPUP_OPENED]: (data)=>trackEvent(GLOVE_FEEDBACK_POPUP_OPENED, data),
    [GloveDataEvents.USER_START_TYPING]: (data)=>trackEvent(GLOVE_FEEDBACK_POPUP_USER_START_TYPING, data),
    [GloveDataEvents.FEEDBACK_SUBMITTED]: (data)=>trackEvent(GLOVE_FEEDBACK_POPUP_SUBMITTED, data),
    [GloveDataEvents.POPUP_CLOSED]: (data)=>trackEvent(GLOVE_FEEDBACK_POPUP_CLOSED, data)
};
// Variable to track if the event listener has already been added
let isMessageListenerAdded = false;
// Initialization function to set up the event listener
const initGloveEventHandler = ()=>{
    // Ensure the code only runs in the browser
    if (typeof window === "undefined") {
        Sentry.captureMessage("initGloveEventHandler called on server side. Skipping initialization.");
        return;
    }
    if (isMessageListenerAdded) return;
    function handleGloveDataEvent(details) {
        const data = {
            "data": details.data
        };
        dispatchEvent(details.eventType, data);
    }
    function dispatchEvent(eventType, data = {}) {
        if (eventHandlers[eventType]) {
            eventHandlers[eventType](data);
        } else {
            Sentry.captureMessage("Unknown event type: " + eventType);
        }
    }
    // Add the message event listener
    window.addEventListener("message", (event)=>{
        if (event.origin !== window.location.origin) {
            Sentry.captureMessage("Untrusted message origin: " + event.origin);
            return;
        }
        if (event.data.type === "GloveDataEvent") {
            handleGloveDataEvent(event.data.details);
        }
    });
    isMessageListenerAdded = true;
};
export default initGloveEventHandler;
